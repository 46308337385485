.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.status-badge-icon {
  font-size: 1.1rem;
}

/* Not Started - Gray theme */
.status-badge.not-started {
  background-color: rgba(100, 116, 139, 0.1);
  color: #475569;
  border: 1px solid rgba(100, 116, 139, 0.3);
}

.status-badge.not-started:hover {
  background-color: rgba(100, 116, 139, 0.15);
}

/* In Progress - Vibrant Gold-Green theme */
.status-badge.in-progress {
  background-color: rgba(76, 175, 80, 0.1);
  color: #2e7d32;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.status-badge.in-progress:hover {
  background-color: rgba(76, 175, 80, 0.15);
}

/* Completed - Blue theme */
.status-badge.completed {
  background-color: rgba(59, 130, 246, 0.1);
  color: #2563eb;
  border: 1px solid rgba(59, 130, 246, 0.3);
}

.status-badge.completed:hover {
  background-color: rgba(59, 130, 246, 0.15);
}

/* Official - Darker Green theme */
.status-badge.official {
  background-color: rgba(20, 83, 45, 0.15);
  color: #14532d;
  border: 1px solid rgba(20, 83, 45, 0.4);
  font-weight: 600;
}

.status-badge.official:hover {
  background-color: rgba(20, 83, 45, 0.2);
}

/* Suspended - Red theme */
.status-badge.suspended {
  background-color: rgba(239, 68, 68, 0.1);
  color: #dc2626;
  border: 1px solid rgba(239, 68, 68, 0.3);
}

.status-badge.suspended:hover {
  background-color: rgba(239, 68, 68, 0.15);
}

/* Default - Gray theme */
.status-badge.default {
  background-color: rgba(75, 85, 99, 0.1);
  color: #4b5563;
  border: 1px solid rgba(75, 85, 99, 0.2);
}

.status-badge.default:hover {
  background-color: rgba(75, 85, 99, 0.15);
}

/* Animation for icon */
.status-badge:hover .status-badge-icon {
  transform: scale(1.1);
  transition: transform 0.2s ease;
} 