/* AllTeamsView specific table styles */
.golf-pools-table .all-teams-pool-number {
    width: 100px;
    min-width: 100px;
    text-align: center;
}

.golf-pools-table .all-teams-tournament {
    width: 180px;
    min-width: 180px;
    text-align: left;
    padding-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.golf-pools-table .all-teams-date {
    width: 100px;
    min-width: 100px;
    text-align: center;
}

.golf-pools-table .all-teams-position {
    width: 160px;
    min-width: 160px;
    text-align: center;
}

.golf-pools-table .all-teams-players {
    width: 100px;
    min-width: 100px;
    text-align: center;
}

.golf-pools-table .all-teams-paid {
    width: 100px;
    min-width: 100px;
    text-align: center;
}

/* Ensure the table takes full width of its container */
.golf-pools-table {
    width: 100%;
    table-layout: fixed;
}

/* Add some spacing between columns */
.golf-pools-table th,
.golf-pools-table td {
    padding: 12px 8px;
}

/* Mobile styles */
.team-card {
    background: white;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.team-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    gap: 12px;
}

.team-card-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    min-width: 0;
}

.team-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.team-card-detail {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 12px;
    align-items: center;
}

.team-card-detail .detail-label {
    color: #666;
    font-weight: 500;
    font-size: 14px;
}

.team-card-detail .detail-value {
    color: #1a472a;
    font-weight: 500;
    font-size: 14px;
}

.team-card .paid-status {
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.team-card .paid-status.paid {
    background-color: #dcfce7;
    color: #166534;
}

.team-card .paid-status.not-paid {
    background-color: #fee2e2;
    color: #991b1b;
}

.golf-pools-rank {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
    overflow: visible !important;
    width: 100% !important;
}

.golf-pools-trophy {
    color: #FFD700 !important;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2)) !important;
    display: inline-flex !important;
    align-items: center !important;
    z-index: 1 !important;
    transform: scale(1) !important;
    transform-origin: center !important;
    position: relative !important;
    margin-right: 8px !important;
}

.golf-pools-trophy:hover {
    transform: scale(1.1) !important;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.3));
}

.rank-text {
    font-weight: 600;
    color: #1a472a;
    display: inline-flex;
    align-items: center;
}

.golf-pools-mc-indicator {
    display: inline-block;
    padding: 2px 8px;
    background-color: #fee2e2;
    color: #dc2626;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
}

.golf-pools-trophy-1 {
    color: #FFD700;
}

.all-teams-position {
    min-width: 140px !important;
    text-align: center !important;
    position: relative !important;
}

.all-teams-tournament {
    font-weight: 500;
    color: #1a472a;
}

.all-teams-date {
    text-align: center;
    color: #4b5563;
    font-weight: 500;
}

.all-teams-pool-number {
    text-align: center;
    font-weight: 600;
    color: #1a472a;
}

.team-status {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.team-status.complete {
    color: #166534;
}

.team-status.incomplete {
    color: #dc2626;
}

/* Mobile card styles */
.pool-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
}

.pool-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding-right: 8px;
}

.pool-card-header h3 {
    font-size: 1rem;
    color: #1a472a;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pool-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.pool-card-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-label {
    color: #6b7280;
    font-size: 0.875rem;
}

.detail-value {
    font-weight: 500;
    color: #1a472a;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .golf-pools-trophy {
        font-size: 1.5rem !important;
        transform: none !important;
        position: static !important;
        margin-right: 8px !important;
    }
    
    .golf-pools-rank {
        margin-left: 0;
        justify-content: flex-start !important;
        padding-left: 0 !important;
        height: 28px !important;
        width: auto !important;
    }

    .pool-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        padding-right: 8px;
    }

    .pool-card-header .golf-pools-rank {
        display: inline-flex !important;
        margin-left: 0 !important;
    }

    .rank-text {
        margin-left: 4px;
        white-space: nowrap;
    }

    .golf-pools-mc-indicator {
        padding: 2px 8px !important;
        font-size: 0.8125rem !important;
        /* min-width: 40px !important; */
    }
}

/* Small mobile styles */
@media screen and (max-width: 480px) {
    .golf-pools-trophy {
        font-size: 1.25rem !important;
    }
}

.golf-pools-mc-teams {
    display: inline-flex !important;
    align-items: center !important;
    color: #ef4444 !important;
    font-size: 0.9375rem !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: 0.05em !important;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .golf-pools-mc-teams {
        font-size: 0.875rem !important;
    }
}

/* Tournament not started styles */
.tournament-not-started {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
    background-color: #f3f4f6;
    border-radius: 6px;
    color: #6b7280;
    font-size: 0.875rem;
    font-weight: 600;
    transition: all 0.2s ease;
    white-space: nowrap;
    min-width: 0;
    max-width: 100%;
}

.tournament-not-started:hover {
    background-color: #e5e7eb;
}

.tournament-not-started .golf-tee-icon {
    font-size: 1.125rem;
    color: #9ca3af;
    flex-shrink: 0;
}

.tournament-not-started span {
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .tournament-not-started {
        padding: 3px 8px;
        font-size: 0.8125rem;
        gap: 4px;
        width: auto;
    }

    .tournament-not-started .golf-tee-icon {
        font-size: 0.9375rem;
    }
}

/* Small mobile styles */
@media screen and (max-width: 480px) {
    .tournament-not-started {
        padding: 2px 6px;
        font-size: 0.75rem;
        gap: 3px;
    }

    .tournament-not-started .golf-tee-icon {
        font-size: 0.875rem;
    }
}

/* Ensure position column doesn't shrink too much */
.golf-pools-table .all-teams-position {
    min-width: 100px !important;
    width: 160px !important;
}

@media screen and (max-width: 768px) {
    .golf-pools-table .all-teams-position {
        min-width: 80px !important;
        width: 120px !important;
    }
} 