/* Common Table Styles */

.golf-pools-card {
    background-color: #e2e8f0;
    border-radius: 16px;
    padding: 1.5rem;
    margin: 5rem auto;
    max-width: 1200px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(226, 232, 240, 0.8);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 90%;
}

.golf-pools-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.golf-pools-titleer {
  margin-bottom: 2rem;
}

.golf-pools-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  position: relative;
}

.golf-pools-back-link {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  background: none;
  border: none;
  color: #1a472a;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 60px;
}

.golf-pools-back-link.left {
  text-align: left;
}

.golf-pools-back-link.right {
  text-align: right;
  justify-content: flex-end;
}

.golf-pools-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a472a;
  margin: 0;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 60px;
}

.golf-pools-table td, .player-cell {
  text-align: left;
}

.golf-pools-sub-title {
  font-size: 1rem;
  font-weight: 400;
  color: #1a472a;
  margin: 0;
  text-align: left;
  flex: 1;
}

.status-badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.golf-pools-table-wrapper {
  background: white;
  border-radius: 12px;
  overflow: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  max-height: calc(100vh - 300px); /* Prevent table from being too tall */
  scrollbar-width: thin;
  scrollbar-color: #1a472a #e2e8f0;
}

/* Scrollbar styling for webkit browsers */
.golf-pools-table-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.golf-pools-table-wrapper::-webkit-scrollbar-track {
  background: #e2e8f0;
  border-radius: 4px;
}

.golf-pools-table-wrapper::-webkit-scrollbar-thumb {
  background: #1a472a;
  border-radius: 4px;
}

.golf-pools-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #2c6d42;
}

.golf-pools-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.golf-pools-table thead {
  background-color: #1a472a;
}

.golf-pools-table th {
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-size: 0.95rem;
  text-align: center;
  border-bottom: none;
}

.golf-pools-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  color: #2d3748;
  font-size: 0.95rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.golf-pools-table tr:last-child td {
  border-bottom: none;
}

/* Add hover effect to table rows */
.golf-pools-table tr:hover:not(.tie-breaker-row) {
  background-color: rgba(26, 71, 42, 0.05);
  transition: background-color 0.2s ease;
}

/* Score styling */
.golf-pools-table .score-under {
  color: red !important;
  font-weight: 600;
}

.golf-pools-table .score-over {
  color: #dc2626 !important;
  font-weight: 600;
}

.golf-pools-table .score-even {
  color: #2d3748 !important;
  font-weight: 600;
}

/* Player status styles */
.golf-pools-table .golf-pools-missed-cut td {
  background-color: #fee2e2;
  color: #991b1b;
}

.golf-pools-table .golf-pools-withdrawn td {
  background-color: #fef3c7;
  color: #92400e;
}

.golf-pools-table .golf-pools-not-counting td {
  background-color: #f3f4f6;
  color: #6b7280;
}

/* Column specific styles */
.golf-pools-table th:first-child,
.golf-pools-table td:first-child {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.golf-pools-table th:nth-child(2),
.golf-pools-table td:nth-child(2) {
  /* width: auto; */
  min-width: 200px;
  text-align: left;
}

.golf-pools-table th:nth-child(3),
.golf-pools-table td:nth-child(3) {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

.golf-pools-table th:nth-child(4),
.golf-pools-table td:nth-child(4) {
  width: 80px;
  min-width: 80px;
  text-align: center;
}

.golf-pools-table th:nth-child(5),
.golf-pools-table td:nth-child(5),
.golf-pools-table th:nth-child(6),
.golf-pools-table td:nth-child(6) {
  width: 120px;
  min-width: 120px;
  text-align: center;
}

/* Status badge styles */
.status-badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.golf-pools-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  min-width: 110px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: none;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .golf-pools-badge {
    padding: 0.35rem 0.75rem;
    font-size: 0.75rem;
    min-width: 90px;
  }
}

@media screen and (max-width: 480px) {
  .golf-pools-badge {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    min-width: 80px;
    letter-spacing: 0.02em;
  }
}

.golf-pools-badge::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  opacity: 0;
  transition: opacity 0.2s ease;
}

.golf-pools-badge:hover::before {
  opacity: 1;
}

.golf-pools-badge:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

.golf-pools-badge.status-not-started {
  background: linear-gradient(135deg, #f3f4f6, #e5e7eb);
  color: #374151;
}

.golf-pools-badge.status-in-progress {
  background: linear-gradient(135deg, #059669, #065f46);
  color: white;
}

.golf-pools-badge.status-suspended {
  background: linear-gradient(135deg, #dc2626, #991b1b);
  color: white;
}

.golf-pools-badge.status-official,
.golf-pools-badge.status-completed {
  background: linear-gradient(135deg, #2563eb, #1e40af);
  color: white;
}

.golf-pools-badge.status-error {
  background: linear-gradient(135deg, #ef4444, #991b1b);
  color: white;
}

.golf-pools-badge.on-grey {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
}

.alignLeft {
  text-align: left !important;
}


.page-back-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    color: #1a472a;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    transition: color 0.2s;
    min-width: 80px;
  }
  
  .page-back-link:hover {
    color: #2f5233;
  }

  .stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
  }
  
  .stat-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    border-color: #cbd5e0;
  }

/* Common Utility Classes */
.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Loading State */
.golf-pools-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    padding: 2rem;
}

/* Empty State */
.golf-pools-empty-container {
  text-align: center;
  color: black;
  font-style: italic;
} 


/* Status Classes Base Styles */
.status-not-started,
.status-in-progress,
.status-suspended,
.status-completed,
.status-official,
.status-error {
  background-color: #e2e8f0;
  color: #475569;
  border: 1px solid #94a3b8;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.status-not-started {
  background-color: #f3f4f6;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.status-in-progress {
  background-color: #dcfce7;
  color: #166534;
  border: 1px solid #166534;
}

.status-suspended {
  background-color: #fecaca;
  color: #991b1b;
  border: 1px solid #991b1b;
}

.status-completed,
.status-official {
  background-color: #dbeafe;
  color: #1e40af;
  border: 1px solid #1e40af;
}

.status-error {
  background-color: #fecaca;
  color: #991b1b;
  border: 1px solid #991b1b;
}

/* On grey background variants */
.status-not-started.on-grey {
  background-color: white;
}

.status-in-progress.on-grey {
  background-color: #dcfce7;
}

.status-suspended.on-grey {
  background-color: #fecaca;
}

.status-completed.on-grey,
.status-official.on-grey {
  background-color: #dbeafe;
}

.status-error.on-grey {
  background-color: #fecaca;
}

/* Mobile Status Text Only Styles */
@media screen and (max-width: 768px) {
  


  .pool-card .status-error {
    color: #991b1b;
  }
}

/* Stats Container */
.stats-data-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  padding: 0.5rem;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .stats-data-container {
    grid-template-columns: repeat(2, 1fr);
  }
}



/* Pool Page Stats */
.pool-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #d1d9e6;
  border: 1px solid #cbd5e0;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .stats-data-container-pool-page {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .stats-data-container-pool-page {
    grid-template-columns: 1fr;
  }
}

.golf-pool-header {
    margin-bottom: 2rem;
  }
  
  .golf-pool-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .golf-pool-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a472a;
    margin: 0;
  }

.golf-pools-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
  padding: 0 1rem;
  width: 100%;
  box-sizing: border-box;
}

.golf-pools-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1a472a;
  color: #dcfce7;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
  flex: 1;
  min-width: 120px;
  text-align: center;
  height: 44px;
  box-sizing: border-box;
}

.golf-pools-action-button:hover {
  background-color: #2c6d42;
  transform: translateY(-1px);
}

.golf-pools-action-button:active {
  transform: translateY(0);
}

.golf-pools-action-button svg {
  font-size: 1.25rem;
}

/* Leaderboard Specific Styles */
.golf-pools-leaderboard-data {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 8px;
  background: #d1d9e6;
  border: 1px solid #cbd5e0;
}

.golf-pools-leaderboard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background: #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #cbd5e0;
}

.golf-pools-leaderboard-label {
  font-size: 0.8rem;
  color: #6b7280;
  margin-bottom: 0.25rem;
  font-weight: 500;
  text-align: center;
}

.golf-pools-leaderboard-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a472a;
  text-align: center;
}

/* Trophy Icons */
.golf-pools-trophy {
  margin-right: 8px;
  font-size: 1.2rem;
}

.golf-pools-trophy-1 {
  color: #FFD700;
}

.golf-pools-trophy-2 {
  color: #C0C0C0;
}

.golf-pools-trophy-3 {
  color: #CD7F32;
}

.golf-pools-rank {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Player Details Table */
.golf-pools-player-details {
  margin-top: 1rem;
  overflow-x: auto;
}

.golf-pools-line-items {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #d1d9e6;
  border-radius: 8px;
  overflow: hidden;
}

.golf-pools-line-items th {
  background: #2c6d42;
  color: white;
  padding: 0.75rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
}

.golf-pools-line-items td {
  padding: 0.75rem;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  font-size: 0.9rem;
  color: #2d3748;
}

.golf-pools-line-items tr:last-child td {
  border-bottom: none;
}

.golf-pools-player-cell {
  display: flex;
  align-items: left;
  gap: 0.5rem;
  padding-left: 1rem;
}

.golf-pools-trophy-icon {
  color: gold;
  padding-left: 3px;
}

.golf-pools-player-name {
  font-weight: 500;
  text-align: left;
  color: #2d3748;
}

.golf-pools-tee-time {
  color: #6c757d;
}

.golf-pools-not-counting {
  opacity: 0.6;
  font-style: italic;
}

.golf-pools-withdrawn {
  color: #dc3545;
  text-decoration: line-through;
}

/* Score Styles */
.golf-pools-score-under {
  color: red !important;
}

.golf-pools-score-over {
  color: #dc3545 !important;
}

.golf-pools-score-even {
  color: #2d3748 !important;
}

.golf-pools-missed-cut {
  color: #dc3545;
  font-style: italic;
}

/* Team Table Styles */
.golf-pools-team-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.golf-pools-team-table th {
  background-color: #1a472a;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 0.95rem;
}

.golf-pools-team-table td {
  padding: 0.75rem 1rem;
  color: #2d3748;
  font-size: 0.95rem;
  border-bottom: 1px solid #e2e8f0;
  text-align: center;
}

.golf-pools-team-table tr:last-child td {
  border-bottom: none;
}

.golf-pools-team-table tr.expanded-row {
  background-color: #f7fafc;
}

.golf-pools-expand-btn {
  background: #1a472a;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.golf-pools-expand-btn:hover {
  background: #2c6d42;
  transform: scale(1.1);
}

.golf-pools-expand-btn svg {
  width: 20px;
  height: 20px;
}

.expanded-row {
  background-color: #f7fafc;
}

.expanded-row .golf-pools-expand-btn svg {
  transform: rotate(180deg);
}

.golf-pools-details-container {
  padding: 1.5rem;
  background: #d1d9e6;
  border-radius: 8px;
  margin: 0.5rem 1rem;
  border: 1px solid #cbd5e0;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.05);
}

.player-details-table {
    margin-top: 1rem;
    overflow-x: auto;
  }
  
  .line-items-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: #d1d9e6;
    border-radius: 8px;
    overflow: hidden;
  }

.golf-pools-details-row td {
  padding: 0;
  background: transparent !important;
}

@keyframes highlightRow {
  from { background-color: rgba(26, 71, 42, 0.1); }
  to { background-color: transparent; }
}

.golf-pools-position-changed {
  animation: highlightRow 2s ease-out;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .golf-pools-leaderboard-data {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .golf-pools-leaderboard-data {
    /* grid-template-columns: 1fr; */
  }
}

.golf-pools-rank-cell,
.golf-pools-team-name,
.golf-pools-world-rank,
.golf-pools-score,
.golf-pools-total,
.golf-pools-status {
  text-align: center;
  font-weight: 500;
  color: #2d3748;
}

.golf-pools-team-name {
  text-align: left;
}

.golf-pools-action {
  width: 60px;
}

.golf-pools-locked-icon {
  color: #718096;
  font-size: 1.25rem;
}

.golf-pools-cut-icon {
  color: #dc3545;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.golf-pools-expanded-row {
  background-color: #f7fafc;
}

/* Clock icon in tee time */
.clock-icon {
  margin-right: 0.25rem;
  font-size: 0.9rem;
}

.expand-collapse-btn {
  background: #1a472a;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0 auto;
}

.expand-collapse-btn:hover {
  background: #2c6d42;
}

.expand-collapse-btn svg {
  width: 20px;
  height: 20px;
}

/* Mobile specific button styles */
@media (max-width: 768px) {
  .leaderboard-card .expand-collapse-btn {
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 0.75rem;
    margin-top: 0.5rem;
    background: #1a472a;
    color: white;
    font-size: 0.9rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;
  }

  .leaderboard-card .expand-collapse-btn:hover,
  .leaderboard-card .expand-collapse-btn.expanded {
    background: #2c6d42;
  }
}

.golf-pools-teams {
  margin-top: 2rem;
}

.golf-pools-section-title {
  font-size: 1.5rem;
  color: #1a472a;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.golf-pools-teams-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
  margin-top: 1rem;
}

.golf-pools-team-card {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  border: 1px solid #e5e7eb;
}

.golf-pools-team-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.golf-pools-team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.golf-pools-team-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a472a;
}

.golf-pools-paid-icon {
  font-size: 1.5rem;
}

.golf-pools-paid-icon.paid {
  color: #22c55e;
}

.golf-pools-paid-icon.unpaid {
  color: #ef4444;
}

.golf-pools-team-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.golf-pools-team-stat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.golf-pools-team-label {
  font-size: 0.875rem;
  color: #6b7280;
  font-weight: 500;
}

.golf-pools-team-value {
  font-size: 1rem;
  font-weight: 600;
  color: #1a472a;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.golf-pools-team-value.complete {
  color: #22c55e;
}

.golf-pools-team-value.incomplete {
  color: #ef4444;
}

.golf-pools-trophy-icon {
  color: #fbbf24;
  font-size: 1.2rem;
  margin-left: 0.25rem;
}

.golf-pools-mc-indicator {
  font-size: 0.875rem;
  font-weight: 600;
  color: #ef4444;
  background-color: #fee2e2;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: inline-block;
}

.golf-pools-team-name-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.golf-pools-trophy-icon.winner {
  color: #fbbf24;
  font-size: 1.25rem;
  margin-left: 0.25rem;
  animation: trophyShine 2s ease-in-out infinite;
}

@keyframes trophyShine {
  0% { opacity: 0.8; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.1); }
  100% { opacity: 0.8; transform: scale(1); }
}

/* Add hover effect to table rows */
.golf-pools-table tr:hover:not(.tie-breaker-row) {
  background-color: rgba(26, 71, 42, 0.05);
  transition: background-color 0.2s ease;
}

.submit-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background-color: #2c6d42;
  transform: translateY(-1px);
}

.submit-button:active {
  transform: translateY(0);
}

.submit-button svg {
  font-size: 1.25rem;
}

/* Score Colors */
.golf-pools-table td.score-under, 
.golf-pools-table td.golf-pools-score-under {
  color: red !important;
}

.golf-pools-table td.score-over,
.golf-pools-table td.score-even,
.golf-pools-table td.golf-pools-score-over,
.golf-pools-table td.golf-pools-score-even {
  color: #2d3748;
}

/* Leaderboard Table Column Widths */
.line-items-table .player-column {
  width: 35%;
  text-align: left;
  min-width: 150px;
}

.line-items-table .round-column {
  width: 8%;
  text-align: center;
  min-width: 50px;
}

.line-items-table .thru-column {
  width: 12%;
  text-align: center;
  min-width: 70px;
}

.line-items-table .score-column {
  width: 10%;
  text-align: center;
  min-width: 60px;
}

.line-items-table .to-par-column {
  width: 11%;
  text-align: center;
  min-width: 65px;
}

/* Ensure table takes full width */
.line-items-table {
  width: 100%;
  table-layout: fixed;
}

/* Add horizontal scrolling for small screens */
.player-details-table {
  overflow-x: auto;
  width: 100%;
}

/* Add these new styles */

/* Desktop/Mobile visibility classes */
.desktop-only {
  display: block;
  width: 100%;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
  }

  .golf-pools-table-wrapper {
    display: none !important;
  }

  .pools-cards-container {
    display: flex !important;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
    margin: 0 0.25rem;
  }

  .golf-pools-card {
    margin: 4rem 0.5rem 5rem;
    padding: 0.75rem;
    width: calc(100% - 1rem);
    border-radius: 12px;
    box-sizing: border-box;
  }

  .pool-card {
    background: white !important;
    margin: 0 0.25rem;
    width: calc(100% - 0.5rem);
    box-sizing: border-box;
    display: block !important;
  }

  .pool-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e2e8f0;
    gap: 0.5rem;
  }

  .pool-card-header h3 {
    font-size: 1rem;
    margin: 0;
    padding-right: 0.5rem;
    flex: 1;
  }

  .pool-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .pool-card-detail {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
  }

  .detail-label {
    color: #64748b;
    font-size: 0.85rem;
    font-weight: 500;
    white-space: nowrap;
    min-width: 100px;
  }

  .detail-value {
    color: #1a472a;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: left;
  }

  .pool-card .tournament-status {
    font-size: 0.7rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 0.025em;
    white-space: nowrap;
    min-width: auto;
    margin-top: 2px;
  }

  .pool-card .tournament-status.status-not-started {
    background-color: #f3f4f6;
    color: #6b7280;
    border: 1px solid #e5e7eb;
  }

  .pool-card .tournament-status.status-in-progress {
    background-color: #dcfce7;
    color: #166534;
    border: 1px solid #166534;
  }

  .pool-card .tournament-status.status-suspended {
    background-color: #fecaca;
    color: #991b1b;
    border: 1px solid #991b1b;
  }

  .pool-card .tournament-status.status-official,
  .pool-card .tournament-status.status-completed {
    background-color: #dbeafe;
    color: #1e40af;
    border: 1px solid #1e40af;
  }

  .golf-pools-header {
    margin-bottom: 1rem;
  }

  .golf-pools-header-row {
    padding: 0.5rem 0;
    min-height: 48px;
  }

  .golf-pools-back-link {
    font-size: 0.85rem;
    padding: 0.35rem;
  }

  .golf-pools-title {
    font-size: 1.1rem;
    padding: 0 50px;
    line-height: 1.2;
  }

  .golf-pools-badge-action {
    min-width: auto;
    padding: 0.5rem 0.5rem;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 375px) {
  .golf-pools-header-row {
    min-height: 44px;
  }

  .golf-pools-title {
    font-size: 1rem;
    padding: 0;
  }

  .golf-pools-badge-action {
    padding: 0.25rem 0.4rem;
    font-size: 0.7rem;
  }
}

/* Team Status Styles */
.team-status {
  font-weight: 500;
  font-size: 0.9rem;
}

.team-status.complete {
  color: #166534;
}

.team-status.incomplete {
  color: #991b1b;
}

.golf-pools-position {
  font-weight: 600;
  font-size: 0.9rem;
  color: #1a472a;
  /* display: flex; */
  align-items: center;
  gap: 0.25rem;
}

.golf-pools-position .golf-pools-trophy-icon {
  color: #fbbf24;
  font-size: 1.1rem;
  margin-left: 0.25rem;
}

@media screen and (max-width: 768px) {
  .team-status {
    font-size: 0.85rem;
  }

  .golf-pools-position {
    font-size: 0.85rem;
  }

  .golf-pools-position .golf-pools-trophy-icon {
    font-size: 1rem;
  }
}

/* Tournament Status Styles */
.tournament-status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  border-radius: 4px;
  transition: all 0.2s ease;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.tournament-status.status-not-started {
  background-color: #f3f4f6;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.tournament-status.status-in-progress {
  background-color: #dcfce7;
  color: #166534;
  border: 1px solid #166534;
}

.tournament-status.status-suspended {
  background-color: #fecaca;
  color: #991b1b;
  border: 1px solid #991b1b;
}

.tournament-status.status-official,
.tournament-status.status-completed {
  background-color: #dbeafe;
  color: #1e40af;
  border: 1px solid #1e40af;
}

@media screen and (max-width: 768px) {
  .tournament-status {
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
    box-shadow: none;
  }

  .pool-card-header {
    gap: 0.5rem;
  }

  .pool-card-header h3 {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 480px) {
  .tournament-status {
    padding: 0.15rem 0.4rem;
    font-size: 0.65rem;
  }
}

/* Round Status Styles */
.round-status {
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.round-status.status-not-started {
  color: #6b7280;
}

.round-status.status-in-progress {
  color: #166534;
}

.round-status.status-suspended {
  color: #991b1b;
}

.round-status.status-official,
.round-status.status-completed {
  color: #1e40af;
}

@media screen and (max-width: 768px) {
  .round-status {
    font-size: 0.85rem;
  }
}

.status-text {
  font-weight: 600;
}

.status-text.status-not-started {
  color: #6b7280;
}

.status-text.status-in-progress {
  color: #166534;
}

.status-text.status-suspended {
  color: #991b1b;
}

.status-text.status-official,
.status-text.status-completed {
  color: #1e40af;
}

@media screen and (max-width: 768px) {
  .status-text {
    font-size: 0.85rem;
  }
}

.round-text {
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
}

.round-text.status-not-started {
  color: #6b7280;
}

.round-text.status-in-progress {
  color: #166534;
}

.round-text.status-suspended {
  color: #991b1b;
}

.round-text.status-official,
.round-text.status-completed {
  color: #1e40af;
}

@media screen and (max-width: 768px) {
  .pool-card .tournament-status,
  .pool-card .round-status,
  .pool-card .status-text,
  .detail-value.status-text {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    font-weight: 600;
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 0.025em;
  }

  .pool-card .tournament-status.status-not-started,
  .pool-card .round-status.status-not-started,
  .pool-card .status-text.status-not-started,
  .detail-value.status-text.status-not-started {
    color: #6b7280 !important;
  }

  .pool-card .tournament-status.status-in-progress,
  .pool-card .round-status.status-in-progress,
  .pool-card .status-text.status-in-progress,
  .detail-value.status-text.status-in-progress {
    color: #166534 !important;
  }

  .pool-card .tournament-status.status-suspended,
  .pool-card .round-status.status-suspended,
  .pool-card .status-text.status-suspended,
  .detail-value.status-text.status-suspended {
    color: #991b1b !important;
  }

  .pool-card .tournament-status.status-official,
  .pool-card .tournament-status.status-completed,
  .pool-card .round-status.status-official,
  .pool-card .round-status.status-completed,
  .pool-card .status-text.status-official,
  .pool-card .status-text.status-completed,
  .detail-value.status-text.status-official,
  .detail-value.status-text.status-completed {
    color: #1e40af !important;
  }
}

/* Pool Page Container Styles */
@media screen and (max-width: 768px) {
  .golf-pools-container {
    padding: 0 0.5rem;
    overflow-x: hidden;
  }

  .golf-pools-header {
    margin-bottom: 0.75rem;
  }

  .golf-pools-back-link {
    padding: 0.35rem 0.75rem;
    font-size: 0.9rem;
  }

  .golf-pools-team-name-container {
    gap: 0.35rem;
  }

  .golf-pools-trophy-icon.winner {
    font-size: 1.1rem;
  }

  .golf-pools-empty-container {
    padding: 2rem 1rem;
    font-size: 0.9rem;
  }

  /* Improve touch targets */
  .golf-pools-team-card {
    min-height: 120px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .golf-pools-team-card:active {
    transform: scale(0.98);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  /* Better spacing for stats */
  .stat-card {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Improve action buttons */
  .golf-pools-action-button {
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .golf-pools-action-button svg {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .golf-pools-container {
    padding: 0 0.35rem;
  }

  .golf-pools-header-row {
    margin-bottom: 0.5rem;
  }

  .stats-data-container {
    margin: 0.5rem 0 1rem;
  }

  .stat-card {
    min-height: 70px;
  }

  .golf-pools-action-button {
    height: 38px;
    margin-bottom: 0.25rem;
  }

  .golf-pools-teams-grid {
    margin-top: 0.5rem;
  }
}

/* Mobile Action Button Styles */
@media screen and (max-width: 768px) {
  .golf-pools-actions {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
    padding: 0 0.5rem;
    margin: 1rem 0;
  }

  .golf-pools-action-button {
    flex: 1 1 calc(50% - 0.375rem);
    min-width: calc(50% - 0.375rem);
    max-width: calc(50% - 0.375rem);
    padding: 0.6rem 1rem;
    height: 40px;
    font-size: 0.9rem;
  }

  .golf-pools-action-button svg {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .golf-pools-actions {
    flex-direction: column;
    padding: 0 0.75rem;
    gap: 0.5rem;
  }

  .golf-pools-action-button {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 42px;
    margin: 0;
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .golf-pools-card {
    margin: 4rem auto 5rem;
    padding: 1rem;
    width: 95%;
    border-radius: 12px;
  }

  .golf-pools-container {
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .golf-pools-card {
    margin: 1rem auto 5rem;
    padding: 0.75rem;
    width: 98%;
  }

  .golf-pools-container {
    padding: 0 0.35rem;
  }
}

.pool-card {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin: 0 0.25rem;
  width: calc(100% - 0.5rem);
  box-sizing: border-box;
  display: block;
}

.pool-card:active {
  transform: scale(0.98);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pool-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
  gap: 0.75rem;
}

.pool-card-header h3 {
  color: #1a472a;
  font-size: 1.1rem;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
  flex: 1;
}

.pool-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.pool-card-detail {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
}

.detail-label {
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
  min-width: 100px;
}

.detail-value {
  color: #1a472a;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: left;
}

/* Team View Mobile Styles */
@media screen and (max-width: 768px) {
  .golf-pools-table-wrapper {
    display: none;
  }

  .team-cards-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .team-card-mobile {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .team-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .team-card-content {
    display: grid;
    gap: 0.5rem;
  }

  .team-card-row {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.75rem;
  }

  .team-card-label {
    color: #6b7280;
    font-size: 0.85rem;
    font-weight: 500;
    min-width: 100px;
  }

  .team-card-value {
    color: #1a472a;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .team-card-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background-color: white;
    color: #1a472a;
    font-size: 0.9rem;
  }

  .team-card-score {
    font-weight: 600;
  }

  .team-card-score.score-under {
    color: #dc2626;
  }

  .team-card-score.score-even {
    color: #1a472a;
  }

  .team-card-score.score-over {
    color: #1a472a;
  }

  .not-selected-text-mobile {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #94a3b8;
    font-size: 0.85rem;
  }

  .not-selected-icon-mobile {
    color: #ef4444;
    font-size: 1rem;
  }

  /* Mobile Tie Breaker Styles */
  .tie-breaker-mobile {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    margin-top: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .tie-breaker-mobile-header {
    font-size: 1rem;
    font-weight: 600;
    color: #1a472a;
    margin-bottom: 0.75rem;
  }

  .tie-breaker-mobile-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.9rem;
    color: #1a472a;
    background: white;
  }

  .tie-breaker-mobile-display {
    font-size: 1rem;
    font-weight: 600;
    color: #1a472a;
    text-align: center;
  }

  .tie-breaker-mobile-helper {
    font-size: 0.8rem;
    color: #6b7280;
    text-align: center;
    margin-top: 0.5rem;
  }
}

/* Team View Styles */
.team-cards-mobile {
  display: none;
}

.golf-pools-table-wrapper {
  display: block;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .golf-pools-table-wrapper {
    display: none;
  }

  .team-cards-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .team-card-mobile {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .team-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .team-card-content {
    display: grid;
    gap: 0.5rem;
  }

  .team-card-row {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0.75rem;
  }

  .team-card-label {
    color: #6b7280;
    font-size: 0.85rem;
    font-weight: 500;
    min-width: 100px;
  }

  .team-card-value {
    color: #1a472a;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .team-card-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background-color: white;
    color: #1a472a;
    font-size: 0.9rem;
  }

  .team-card-score {
    font-weight: 600;
  }

  .team-card-score.score-under {
    color: #dc2626;
  }

  .team-card-score.score-even {
    color: #1a472a;
  }

  .team-card-score.score-over {
    color: #1a472a;
  }

  .not-selected-text-mobile {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #94a3b8;
    font-size: 0.85rem;
  }

  .not-selected-icon-mobile {
    color: #ef4444;
    font-size: 1rem;
  }

  /* Mobile Tie Breaker Styles */
  .tie-breaker-mobile {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    margin-top: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .tie-breaker-mobile-header {
    font-size: 1rem;
    font-weight: 600;
    color: #1a472a;
    margin-bottom: 0.75rem;
  }

  .tie-breaker-mobile-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.9rem;
    color: #1a472a;
    background: white;
  }

  .tie-breaker-mobile-display {
    font-size: 1rem;
    font-weight: 600;
    color: #1a472a;
    text-align: center;
  }

  .tie-breaker-mobile-helper {
    font-size: 0.8rem;
    color: #6b7280;
    text-align: center;
    margin-top: 0.5rem;
  }
}

/* Leaderboard Mobile Styles */
.leaderboard-cards-mobile {
  display: none;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.leaderboard-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.leaderboard-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.leaderboard-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.leaderboard-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
}

.leaderboard-card-label {
  color: #6b7280;
  font-size: 0.9rem;
}

.leaderboard-card-value {
  font-weight: 500;
  color: #1a472a;
  padding-left: 5px;
}

.leaderboard-card .rank-with-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.leaderboard-card .trophy-icon {
  font-size: 1.2rem;
}

.leaderboard-card .trophy-1 { color: #ffd700; }
.leaderboard-card .trophy-2 { color: #c0c0c0; }
.leaderboard-card .trophy-3 { color: #cd7f32; }

.leaderboard-card .expand-collapse-btn {
  background: none;
  border: none;
  color: #1a472a;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.2rem;
  transition: transform 0.2s ease;
}

.leaderboard-card .expand-collapse-btn.expanded {
  transform: rotate(90deg);
}

.leaderboard-card .details-container {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .golf-pools-table-wrapper {
    display: none;
  }

  .leaderboard-cards-mobile {
    display: flex;
  }

  .leaderboard-card .player-details-table {
    overflow-x: auto;
    margin: 0.5rem -1rem;
    padding: 0 1rem;
  }

  .leaderboard-card .line-items-table {
    font-size: 0.9rem;
    width: 100%;
    min-width: 600px;
  }
}

/* Leaderboard specific table styles */
.leaderboard-table-wrapper {
  overflow-x: auto;
  margin: 1rem 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding-bottom: 1px;
  overflow-y: hidden; /* Prevent vertical scroll */
}

/* Table Layout */
.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  table-layout: fixed;
}

/* Column Widths */
.leaderboard-table th.rank-header,
.leaderboard-table td.rank-cell {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.leaderboard-table th.team-header,
.leaderboard-table td.team-name-cell {
  width: auto;
  text-align: left;
  padding-left: 1.5rem;
}

.leaderboard-table th.world-rank-header,
.leaderboard-table td.world-rank-cell,
.leaderboard-table th.score-header,
.leaderboard-table td.score-cell {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  text-align: center;
  padding: 0.75rem 0;
}

.leaderboard-table th.details-header,
.leaderboard-table td.action-cell {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

/* Hover tooltip adjustments */
.locked-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.locked-container .locked-icon {
  margin: 0;
}

.locked-container:hover::after,
.locked-container:hover::before {
  position: fixed;
  transform: translateX(-50%);
}

.locked-container:hover::after {
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Prevent text wrapping */
.leaderboard-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaderboard-table th {
  background-color: #1a472a;
  font-weight: 500;
  text-align: left;
  color: white;
  white-space: nowrap;
}

.leaderboard-table tbody tr:hover {
  background-color: #f9fafb;
}

.leaderboard-table th:first-child,
.leaderboard-table td:first-child {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.leaderboard-table .rank-cell {
  text-align: center;
}

.leaderboard-table .team-name-cell {
  text-align: left;
}

.leaderboard-table .world-rank-cell,
.leaderboard-table .score-cell {
  text-align: center;
}

.leaderboard-table .action-cell {
  width: 80px;
  text-align: center;
}

.leaderboard-table tr.expanded-row {
  background-color: #f3f4f6;
}

.leaderboard-table tr.position-changed {
  animation: highlight 2s ease-in-out;
}

.leaderboard-table .details-row {
  background-color: #f9fafb;
}

.leaderboard-table .details-container {
  padding: 0.5rem;
}

.leaderboard-table .expand-collapse-btn {
  background: #1a472a;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.leaderboard-table .expand-collapse-btn:hover {
  background: #2c6d42;
  transform: scale(1.1);
}

.leaderboard-table .expand-collapse-btn svg {
  width: 20px;
  height: 20px;
}

@keyframes highlight {
  0% { background-color: #fef3c7; }
  100% { background-color: transparent; }
}

/* Header alignment styles */
.leaderboard-table th.rank-header {
  text-align: center;
}

.leaderboard-table th.team-header {
  text-align: left;
  padding-left: 1rem;
}

.leaderboard-table th.world-rank-header,
.leaderboard-table th.score-header {
  text-align: right;
  padding-right: 2rem;
}

.leaderboard-table th.details-header {
  text-align: center;
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .leaderboard-table-wrapper {
    display: none;
  }

  .leaderboard-cards-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .leaderboard-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 1rem;
  }

  .leaderboard-card-header {
    margin-bottom: 0.75rem;
  }

  .leaderboard-card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  .leaderboard-card-label {
    color: #6b7280;
    font-size: 0.9rem;
  }

  .leaderboard-card-value {
    font-weight: 500;
    color: #1a472a;
  }

  .leaderboard-card .expand-collapse-btn {
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 0.75rem;
    margin-top: 0.5rem;
    background: #1a472a;
    color: white;
    font-size: 0.9rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  .leaderboard-card .expand-collapse-btn.expanded {
    background: #1a472a;
  }

  .leaderboard-card .expand-collapse-btn:hover {
    background: #2c6d42;
    transform: none;
  }

  .leaderboard-card .details-container {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }

  .leaderboard-card .golf-pools-score-under {
    color: #dc2626;
  }
}

/* Mobile player details table styles */
@media (max-width: 768px) {
  .leaderboard-card .details-container {
    margin: 1rem 0 0 0;
    padding: 0;
    border-top: 1px solid #e5e7eb;
    width: 100%;
    overflow: hidden;
  }

  .player-details-table {
    width: 100%;
    overflow-x: hidden;
  }

  .line-items-table.mobile-table {
    width: 100%;
    margin: 0;
    table-layout: fixed;
  }

  .line-items-table.mobile-table .player-column {
    width: 50%;
    text-align: left;
  }

  .line-items-table.mobile-table .round-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .thru-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .to-par-column {
    width: 20%;
    text-align: center;
  }

  .line-items-table.mobile-table td,
  .line-items-table.mobile-table th {
    padding: 8px 4px;
    font-size: 0.9rem;
  }

  .line-items-table.mobile-table .player-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 4px;
  }

  /* Remove button rotation */
  .leaderboard-card .expand-collapse-btn.expanded {
    transform: none;
  }

  .leaderboard-card .expand-collapse-btn:hover {
    transform: none;
  }
}

/* Mobile table styles */
@media (max-width: 768px) {
  .leaderboard-card .details-container {
    margin: 1rem 0 0 0;
    padding: 0;
    border-top: 1px solid #e5e7eb;
    width: 100%;
    overflow: hidden;
  }

  .player-details-table {
    width: 100%;
  }

  .line-items-table.desktop-table {
    display: none;
  }

  .line-items-table.mobile-table {
    display: table;
    width: 100%;
    font-size: 0.85rem;
    table-layout: fixed;
  }

  .line-items-table.mobile-table th,
  .line-items-table.mobile-table td {
    padding: 8px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .line-items-table.mobile-table .player-column {
    width: 40%;
  }

  .line-items-table.mobile-table .round-column {
    width: 20%;
    text-align: center;
  }

  .line-items-table.mobile-table .thru-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .to-par-column {
    width: 25%;
    text-align: right;
  }

  .line-items-table.mobile-table .player-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  .line-items-table.mobile-table thead th {
    font-weight: 500;
    color: #1a472a;
    background-color: #f3f4f6;
    border-bottom: 1px solid #e5e7eb;
  }

  .line-items-table.mobile-table tbody tr:nth-child(even) {
    background-color: #f9fafb;
  }

  .line-items-table.mobile-table .golf-pools-mc-indicator {
    font-size: 0.8rem;
    padding: 2px 4px;
  }

  .line-items-table.mobile-table .tee-time {
    font-size: 0.8rem;
  }
}

/* Leaderboard specific table styles */
.leaderboard-table-wrapper {
  overflow-x: auto;
  margin: 1rem 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
  color: #1a472a;
  font-size: 0.95rem;
}

.leaderboard-table th {
  background-color: #1a472a;
  font-weight: 500;
  text-align: left;
  color: white;
  white-space: nowrap;
}

.leaderboard-table tbody tr:hover {
  background-color: #f9fafb;
}

.leaderboard-table th:first-child,
.leaderboard-table td:first-child {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  text-align: center;
}

.leaderboard-table .rank-cell {
  text-align: center;
}

.leaderboard-table .team-name-cell {
  text-align: left;
}

.leaderboard-table .world-rank-cell,
.leaderboard-table .score-cell {
  text-align: center;
}

.leaderboard-table .action-cell {
  width: 80px;
  text-align: center;
}

.leaderboard-table tr.expanded-row {
  background-color: #f3f4f6;
}

.leaderboard-table tr.position-changed {
  animation: highlight 2s ease-in-out;
}

.leaderboard-table .details-row {
  background-color: #f9fafb;
}

.leaderboard-table .details-container {
  padding: 0.5rem;
}

.leaderboard-table .expand-collapse-btn {
  background: #1a472a;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.leaderboard-table .expand-collapse-btn:hover {
  background: #2c6d42;
  transform: scale(1.1);
}

.leaderboard-table .expand-collapse-btn svg {
  width: 20px;
  height: 20px;
}

@keyframes highlight {
  0% { background-color: #fef3c7; }
  100% { background-color: transparent; }
}

/* Header alignment styles */
.leaderboard-table th.rank-header {
  text-align: center;
}

.leaderboard-table th.team-header {
  text-align: left;
  padding-left: 1rem;
}

.leaderboard-table th.world-rank-header,
.leaderboard-table th.score-header {
  text-align: right;
  padding-right: 2rem;
}

.leaderboard-table th.details-header {
  text-align: center;
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .leaderboard-table-wrapper {
    display: none;
  }

  .leaderboard-cards-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .leaderboard-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 1rem;
  }

  .leaderboard-card-header {
    margin-bottom: 0.75rem;
  }

  .leaderboard-card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  .leaderboard-card-label {
    color: #6b7280;
    font-size: 0.9rem;
  }

  .leaderboard-card-value {
    font-weight: 500;
    color: #1a472a;
  }

  .leaderboard-card .expand-collapse-btn {
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 0.75rem;
    margin-top: 0.5rem;
    background: #1a472a;
    color: white;
    font-size: 0.9rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  .leaderboard-card .expand-collapse-btn.expanded {
    background: #1a472a;
  }

  .leaderboard-card .expand-collapse-btn:hover {
    background: #2c6d42;
    transform: none;
  }

  .leaderboard-card .details-container {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }

  .leaderboard-card .golf-pools-score-under {
    color: #dc2626;
  }
}

/* Mobile player details table styles */
@media (max-width: 768px) {
  .leaderboard-card .details-container {
    margin: 1rem 0 0 0;
    padding: 0;
    border-top: 1px solid #e5e7eb;
    width: 100%;
    overflow: hidden;
  }

  .player-details-table {
    width: 100%;
    overflow-x: hidden;
  }

  .line-items-table.mobile-table {
    width: 100%;
    margin: 0;
    table-layout: fixed;
  }

  .line-items-table.mobile-table .player-column {
    width: 50%;
    text-align: left;
  }

  .line-items-table.mobile-table .round-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .thru-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .to-par-column {
    width: 20%;
    text-align: center;
  }

  .line-items-table.mobile-table td,
  .line-items-table.mobile-table th {
    padding: 8px 4px;
    font-size: 0.9rem;
  }

  .line-items-table.mobile-table .player-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 4px;
  }

  /* Remove button rotation */
  .leaderboard-card .expand-collapse-btn.expanded {
    transform: none;
  }

  .leaderboard-card .expand-collapse-btn:hover {
    transform: none;
  }
}

/* Mobile table styles */
@media (max-width: 768px) {
  .leaderboard-card .details-container {
    margin: 1rem 0 0 0;
    padding: 0;
    border-top: 1px solid #e5e7eb;
    width: 100%;
    overflow: hidden;
  }

  .player-details-table {
    width: 100%;
  }

  .line-items-table.desktop-table {
    display: none;
  }

  .line-items-table.mobile-table {
    display: table;
    width: 100%;
    font-size: 0.85rem;
    table-layout: fixed;
  }

  .line-items-table.mobile-table th,
  .line-items-table.mobile-table td {
    padding: 8px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .line-items-table.mobile-table .player-column {
    width: 40%;
  }

  .line-items-table.mobile-table .round-column {
    width: 20%;
    text-align: center;
  }

  .line-items-table.mobile-table .thru-column {
    width: 15%;
    text-align: center;
  }

  .line-items-table.mobile-table .to-par-column {
    width: 25%;
    text-align: right;
  }

  .line-items-table.mobile-table .player-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  .line-items-table.mobile-table thead th {
    font-weight: 500;
    color: #1a472a;
    background-color: #f3f4f6;
    border-bottom: 1px solid #e5e7eb;
  }

  .line-items-table.mobile-table tbody tr:nth-child(even) {
    background-color: #f9fafb;
  }

  .line-items-table.mobile-table .golf-pools-mc-indicator {
    font-size: 0.8rem;
    padding: 2px 4px;
  }

  .line-items-table.mobile-table .tee-time {
    font-size: 0.8rem;
  }
}

.golf-pools-badge-action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 80px;
  text-align: center;
}

.golf-pools-badge-action:hover {
  background-color: #34855a;
  color: white;  /* Ensure text stays white on hover */
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.golf-pools-badge-action:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .golf-pools-badge-action {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    min-width: 70px;
  }
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.item-header h3 {
  color: #1a472a;
  margin: 0;
  font-size: 1rem;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-header .item-status {
  flex-shrink: 0;
  min-width: fit-content;
}

@media screen and (max-width: 768px) {
  .item-header {
    gap: 0.75rem;
  }
  
  .item-header h3 {
    font-size: 0.9rem;
  }
} 

/* Team Table Specific Styles */
.team-table {
  width: 100% !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
  background: white !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  table-layout: fixed !important;
}

.team-table th {
  background-color: #1a472a !important;
  color: white !important;
  font-weight: 500 !important;
  padding: 1rem !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.team-table-player-header {
  width: 55% !important;
  text-align: left !important;
  padding-left: 1.5rem !important;
}

.team-table-rank-header,
.team-table-position-header,
.team-table-score-header {
  width: 15% !important;
  text-align: center !important;
}

.team-table td {
  padding: 0.75rem 1rem !important;
  border-bottom: 1px solid #e2e8f0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.team-table tr:last-child td {
  border-bottom: none !important;
}

.team-table-player-cell {
  width: 50% !important;
  text-align: left !important;
  padding-left: 1.5rem !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.team-table-rank-cell,
.team-table-position-cell,
.team-table-score-cell {
  width: 16% !important;
  text-align: center !important;
}

/* Score Classes */
.score-under {
  color: red !important;
}

.score-over {
  color: #2d3748 !important;
}

.score-even {
  color: #2d3748 !important;
}

/* Status Classes */
.golf-pools-missed-cut {
  color: #dc3545;
  font-style: italic;
}

.golf-pools-withdrawn {
  color: #dc3545;
  text-decoration: line-through;
}

.golf-pools-not-counting {
  opacity: 0.6;
  font-style: italic;
}

/* Add these styles at the end of the file */

/* Team Mobile View Styles */
@media screen and (max-width: 768px) {
  .team-cards-mobile {
    display: flex !important;
    flex-direction: column !important;
    gap: 0.75rem !important;
    padding: 0.5rem !important;
  }

  .team-card-mobile {
    background: white !important;
    border-radius: 12px !important;
    padding: 1rem !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #e5e7eb !important;
  }

  .team-card-content {
    display: grid !important;
    gap: 0.75rem !important;
  }

  .team-card-row {
    display: grid !important;
    grid-template-columns: 120px 1fr !important;
    align-items: center !important;
    gap: 1rem !important;
  }

  .team-card-label {
    color: #6b7280 !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
  }

  .team-card-value {
    color: #1a472a !important;
    font-size: 0.95rem !important;
    font-weight: 500 !important;
  }

  .team-card-score.score-under {
    color: red !important;
  }

  .team-card-score.score-over,
  .team-card-score.score-even {
    color: #2d3748 !important;
  }

  /* Status styles for mobile */
  .team-card-mobile.golf-pools-missed-cut {
    opacity: 0.8 !important;
  }

  .team-card-mobile.golf-pools-missed-cut .team-card-value {
    color: #dc3545 !important;
    font-style: italic !important;
  }

  .team-card-mobile.golf-pools-withdrawn .team-card-value {
    color: #dc3545 !important;
    text-decoration: line-through !important;
  }

  .team-card-mobile.golf-pools-not-counting {
    opacity: 0.6 !important;
    font-style: italic !important;
  }
}

/* Desktop/Mobile table visibility */
.line-items-table.desktop-table {
  display: table;
  width: 100%;
}

.line-items-table.mobile-table {
  display: none;
}

@media screen and (max-width: 768px) {
  .line-items-table.desktop-table {
    display: none;
  }

  .line-items-table.mobile-table {
    display: table;
    width: 100%;
  }
}

/* Team Table Mobile Styles */
.line-items-table.mobile-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #d1d9e6;
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  table-layout: fixed;
}

.line-items-table.mobile-table th {
  background: #1a472a;
  color: white;
  padding: 8px 4px;
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
}

.line-items-table.mobile-table td {
  padding: 8px 4px;
  font-size: 0.85rem;
  border-bottom: 1px solid #e5e7eb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line-items-table.mobile-table tr:last-child td {
  border-bottom: none;
}

/* Mobile column widths */
.line-items-table.mobile-table .player-column {
  width: 45%;
  text-align: left;
  padding-left: 8px;
}

.line-items-table.mobile-table .round-column {
  width: 20%;
  text-align: center;
}

.line-items-table.mobile-table .thru-column {
  width: 15%;
  text-align: center;
}

.line-items-table.mobile-table .to-par-column {
  width: 20%;
  text-align: center;
  padding-right: 8px;
}

/* Player name truncation */
.line-items-table.mobile-table .player-name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 4px;
}

/* Mobile status indicators */
.line-items-table.mobile-table .golf-pools-mc-indicator {
  font-size: 0.75rem;
  padding: 2px 4px;
}

.line-items-table.mobile-table .tee-time {
  font-size: 0.75rem;
  white-space: nowrap;
}

.line-items-table.mobile-table .clock-icon {
  font-size: 0.75rem;
  margin-right: 2px;
}

/* Remove horizontal scroll from container */
@media screen and (max-width: 768px) {
  .player-details-table {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  .details-container {
    margin: 0.5rem 0 0 0;
    padding: 0;
    overflow: hidden;
  }
}

/* Leaderboard Line Items Table */
.leaderboard-player-details {
  margin-top: 1rem;
  overflow-x: auto;
}

.leaderboard-line-items {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #d1d9e6;
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  table-layout: fixed;
}

.leaderboard-line-items th {
  background: #1a472a;
  color: white;
  padding: 12px 8px;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
}

.leaderboard-line-items td {
  padding: 12px 8px;
  font-size: 0.9rem;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
}

.leaderboard-line-items tr:last-child td {
  border-bottom: none;
}

/* Column styles */
.leaderboard-player-column {
  width: 35%;
  text-align: left !important;
}

.leaderboard-round-column {
  width: 10%;
}

.leaderboard-thru-column {
  width: 10%;
}

.leaderboard-score-column {
  width: 12%;
}

.leaderboard-to-par-column {
  width: 13%;
}

/* Cell styles */
.leaderboard-player-cell {
  text-align: left !important;
  padding-left: 16px !important;
}

.leaderboard-player-name {
  font-weight: 500;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leaderboard-round-cell,
.leaderboard-thru-cell,
.leaderboard-total-cell,
.leaderboard-to-par-cell {
  text-align: center;
}

/* Status indicators */
.leaderboard-mc-indicator {
  font-size: 0.85rem;
  font-weight: 600;
  color: #ef4444;
  background-color: #fee2e2;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
}

.leaderboard-tee-time {
  color: #6b7280;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.leaderboard-clock-icon {
  font-size: 0.85rem;
}
.leaderboard-total-cell {
  color: #1a472a !important;
}

/* Player status styles */
.leaderboard-missed-cut {
  color: black;
  font-style: italic;
}

.leaderboard-withdrawn {
  color: #ef4444;
  text-decoration: line-through;
}

.leaderboard-not-counting {
  opacity: 0.6;
  font-style: italic;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .leaderboard-line-items.desktop-table {
    display: none;
  }

  .leaderboard-line-items.mobile-table {
    display: table;
  }

  .leaderboard-line-items.mobile-table th,
  .leaderboard-line-items.mobile-table td {
    padding: 8px 4px;
    font-size: 0.85rem;
  }

  .leaderboard-line-items.mobile-table .leaderboard-player-column {
    width: 45%;
  }

  .leaderboard-line-items.mobile-table .leaderboard-round-column {
    width: 20%;
  }

  .leaderboard-line-items.mobile-table .leaderboard-thru-column {
    width: 15%;
  }

  .leaderboard-line-items.mobile-table .leaderboard-to-par-column {
    width: 20%;
  }

  .leaderboard-player-details {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
}

/* Mobile column widths */
.leaderboard-line-items.mobile-table .leaderboard-player-column {
  width: 40%;
  text-align: left;
  padding-left: 8px;
}

.leaderboard-line-items.mobile-table .leaderboard-round-column {
  width: 20%;
  text-align: center;
}

.leaderboard-line-items.mobile-table .leaderboard-thru-column {
  width: 20%;
  text-align: center;
}

.leaderboard-line-items.mobile-table .leaderboard-to-par-column {
  width: 20%;
  text-align: center;
  padding-right: 8px;
}

/* Ensure table stays within container */
@media screen and (max-width: 768px) {
  .leaderboard-player-details {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .leaderboard-line-items.mobile-table {
    min-width: 100%;
    max-width: 100%;
  }

  /* Improve truncation for player names */
  .leaderboard-player-cell {
    max-width: 0;  /* Force truncation */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .leaderboard-player-name {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  /* Ensure other cells don't wrap */
  .leaderboard-round-cell,
  .leaderboard-thru-cell,
  .leaderboard-to-par-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.leaderboard-line-items.mobile-table {
  display: none !important;
}

.leaderboard-line-items.desktop-table {
  display: table !important;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .leaderboard-line-items.desktop-table {
    display: none !important;
  }

  .leaderboard-line-items.mobile-table {
    display: table !important;
    width: 100%;
  }
}

/* Team Card Select Styles - Applied to both desktop and mobile */
.team-card-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: white;
  color: #1a472a;
  font-size: 0.9rem;
  font-weight: 500;
}

.team-card-select:focus {
  outline: none;
  border-color: #1a472a;
  box-shadow: 0 0 0 2px rgba(26, 71, 42, 0.1);
}

.team-card-select option {
  padding: 0.5rem;
  font-size: 0.9rem;
}

.team-card-select option:disabled {
  color: #9ca3af;
  background-color: #f3f4f6;
}

/* Team View Mobile Styles */
@media screen and (max-width: 768px) {
  .golf-pools-table-wrapper {
    display: none;
  }

  .team-cards-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .team-card-mobile {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
}

/* Team View Table Styles */
.team-view-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.team-view-table thead {
  background-color: #1a472a;
}

.team-view-table th {
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-size: 0.95rem;
  text-align: center;
}

.team-view-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  color: #2d3748;
  font-size: 0.95rem;
}

.team-view-table tr:last-child td {
  border-bottom: none;
}

/* Column widths for team view table */
.team-view-table .player-column {
  width: 60%;
  text-align: left;
}

.team-view-table .rank-column,
.team-view-table .position-column,
.team-view-table .score-column {
  width: 13.33%;
  text-align: center;
}

/* Score styling */
.team-view-table .score-under {
  color: red !important;
  font-weight: 600;
}

.team-view-table .score-over {
  color: #dc2626 !important;
  font-weight: 600;
}

.team-view-table .score-even {
  color: #2d3748 !important;
  font-weight: 600;
}

/* Player status styles */
.team-view-table .golf-pools-missed-cut td {
  background-color: #fee2e2;
  color: #991b1b;
}

.team-view-table .golf-pools-withdrawn td {
  background-color: #fef3c7;
  color: #92400e;
}

.team-view-table .golf-pools-not-counting td {
  background-color: #f3f4f6;
  color: #6b7280;
}

/* Add hover effect to table rows */
.team-view-table tr:hover:not(.tie-breaker-row) {
  background-color: rgba(26, 71, 42, 0.05);
  transition: background-color 0.2s ease;
}

/* Text alignment helper */
.text-center {
  text-align: center;
}

/* Team Card Mobile Styles */
.team-card-mobile {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
}

.team-card-mobile .not-selected-text {
  /* color: #dc2626; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.team-card-mobile .not-selected-icon {
  font-size: 1.1rem;
}

.team-card-value .not-selected-text {
  /* color: #dc2626; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

/* Disabled row styles */
.leaderboard-table tr.disabled-row {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #f3f4f6;
}

.leaderboard-table tr.disabled-row:hover {
  background-color: #f3f4f6;
}

.leaderboard-table tr.disabled-row td {
  color: #6b7280;
}

.leaderboard-table tr.disabled-row .locked-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}

.leaderboard-table tr.disabled-row .locked-icon {
  color: #6b7280;
  font-size: 1.25rem;
}

/* Mobile card disabled styles */
.leaderboard-cards-mobile .leaderboard-card.disabled-card {
  opacity: 0.7;
  background-color: #f3f4f6;
  cursor: not-allowed;
}

.leaderboard-cards-mobile .leaderboard-card.disabled-card .leaderboard-card-value {
  color: #6b7280;
}

.leaderboard-cards-mobile .leaderboard-card .locked-message {
  color: #6b7280;
  font-size: 0.875rem;
  text-align: center;
  padding: 8px;
  border: 1px dashed #d1d5db;
  border-radius: 4px;
  margin-top: 8px;
}

/* Hover effects for locked icons */
.locked-container {
  position: relative;
}

.locked-container:hover::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: #1a472a;
  color: white;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 10;
  pointer-events: none;
}

.locked-container:hover::before {
  content: '';
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #1a472a transparent transparent transparent;
  pointer-events: none;
}

/* Leaderboard Table Column Styles */
.leaderboard-table th.world-rank-header,
.leaderboard-table th.score-header {
  text-align: center !important;
  padding-right: 0 !important;
}

.leaderboard-table .world-rank-cell,
.leaderboard-table .score-cell {
  text-align: center !important;
}

/* Tournament Stats Mobile Styles */
@media screen and (max-width: 768px) {
  .golf-pools-header-row {
    padding: 8px;
    min-height: 48px;
  }

  .golf-pools-title {
    font-size: 1.1rem;
    max-width: calc(100% - 160px);
    margin: 0 10px;
    line-height: 1.2;
    padding: 0;
  }

  .golf-pools-back-link {
    padding: 4px 8px;
    font-size: 0.9rem;
    min-width: 60px;
  }

  .golf-pools-container {
    padding: 0 0.5rem;
  }
}