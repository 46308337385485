.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a472a;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 9999;
  box-sizing: border-box;
}

.nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-links a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.3rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-links a.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-text {
  display: inline;
}

.nav-right {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 0;
  margin-right: -1rem;
}

.user-dropdown {
  position: relative;
  display: inline-block;
  padding-right: 1rem;
}

.user-button {
  background: none;
  border: none;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.dropdown-content {
  position: fixed;
  top: 60px;
  right: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 4px 20px rgba(22, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.2s ease-in-out;
  border-top-right-radius: 0;
}

.user-dropdown:hover .dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  color: #1a472a;
  padding: 0.75rem 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  font-weight: 500;
}

.dropdown-item:hover {
  background-color: rgba(8, 111, 44, 0.128);
  padding-left: 1.5rem;
}

.dropdown-item svg {
  font-size: 1.1em;
}

.logout-link {
  color: #dc2626;
  border-top: 1px solid #f3f4f6;
  margin-top: 5px;
}

.logout-link:hover {
  background-color: rgba(220, 38, 38, 0.1);
}

.mobile-account {
  display: none;
  position: relative;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .navbar {
    top: auto;
    bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-text {
    display: none;
  }

  .nav-links {
    gap: 0.5rem;
  }

  .nav-links a {
    padding: 1rem;
  }

  .desktop-only {
    display: none;
  }

  .mobile-account {
    display: block;
    position: relative;
    padding-right: 0;
    margin-right: -1rem;
  }

  .mobile-account-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
  }

  .mobile-menu {
    position: fixed;
    bottom: 60px;
    right: 0;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    min-width: 200px;
    z-index: 9998;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    margin-bottom: 5px;
  }

  .mobile-menu-item {
    color: #1a472a;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
  }

  .mobile-menu-item:hover {
    background-color: rgba(26, 71, 42, 0.1);
    padding-left: 1.5rem;
  }

  .logout-link:hover {
    background-color: rgba(220, 38, 38, 0.1);
  }
  
  .mobile-menu-item.logout-link {
    color: #dc2626;
    border-top: 1px solid #f3f4f6;
    margin-top: 5px;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    padding: 0;
    justify-content: space-between;
    height: 60px;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #1a472a;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }

  .nav-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.5rem;
  }

  .nav-text {
    display: block;
    font-size: 0.7rem;
    margin-top: 0.25rem;
  }

  .navbar a {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    flex: 1;
    gap: 0.25rem;
  }

  .navbar a svg {
    font-size: 1.25rem;
  }

  .mobile-menu {
    position: fixed;
    bottom: calc(60px + env(safe-area-inset-bottom));
    right: 0;
    z-index: 9998;
    margin-bottom: 5px;
  }

  body {
    padding-bottom: calc(60px + env(safe-area-inset-bottom));
  }

  .nav-right {
    margin-right: 0;
  }

  .mobile-account {
    margin-right: 0;
    padding-right: 0;
  }
}

/* Show dropdown on hover for desktop */
@media screen and (min-width: 769px) {
  .user-dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Hide the click-based dropdown on desktop */
  .user-dropdown[data-dropdown-open="true"] .dropdown-content {
    display: none;
  }
}

