.pool-modal-nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.nav-bar-buttons {
  display: flex;
  gap: 1rem;
}

/* .modal-nav-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
} */

.modal-nav-button.primary {
  background-color: #1a472a;
  color: white;
}

.modal-nav-button.primary:hover {
  background-color: #2f5233;
}

.modal-nav-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.invite-buttons-container {
  display: flex;
  gap: 1rem;
}

.add-email-button,
.submit-invite-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.add-email-button {
  background-color: #e2e8f0;
  color: #2d3748;
}

.add-email-button:hover {
  background-color: #cbd5e0;
}

.submit-invite-button {
  background-color: #1a472a;
  color: white;
}

.submit-invite-button:hover {
  background-color: #2f5233;
}

.submit-invite-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 