.invite-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
}

.invite-content {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
}

.invite-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.invite-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a472a;
  margin: 0;
}

.invite-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.email-input-row {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
}

.email-input {
  flex: 1;
  padding: 0.875rem 1.25rem;
  border: 2px solid #eef1f4;
  border-radius: 10px;
  font-size: 1rem;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.3s ease;
  /* height: 3rem; */
}

.email-input:focus {
  outline: none;
  border-color: #1a472a;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.email-input:disabled {
  background-color: #f7fafc;
  cursor: not-allowed;
}

.email-remove-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.email-remove-button:hover {
  background-color: rgba(229, 62, 62, 0.1);
}

.email-remove-button:disabled {
  color: #a0aec0;
  cursor: not-allowed;
}

.success-message,
.error-message {
  padding: 12px 16px;
  border-radius: 8px;
  margin: 10px auto;
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  animation: slideIn 0.3s ease-out;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.success-message {
  background-color: #10b981;
  color: white;
  border: none;
}

.error-message {
  background-color: #ef4444;
  color: white;
  border: none;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.add-email-button {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #eef1f4;
  color: #1a472a;
  border: none;
  border-radius: 10px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.add-email-button:hover {
  background-color: #e2e8f0;
}

.add-email-button:disabled {
  background-color: #f7fafc;
  color: #a0aec0;
  cursor: not-allowed;
}

.submit-invite-button {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.submit-invite-button:hover {
  background-color: #2c6a43;
}

.submit-invite-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .invite-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .invite-actions {
    flex-direction: row;
    gap: 1rem;
  }

  .email-input-row {
    flex-direction: row;
  }

  .email-input {
    width: 100%;
  }

  .add-email-button,
  .submit-invite-button {
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
  }
} 