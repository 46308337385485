.faq-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f3f4f6;
}

.faq-card {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.faq-category {
  margin-bottom: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.faq-category h2 {
  padding: 1.5rem;
  margin: 0;
  background-color: #1a472a;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.faq-question {
  border-bottom: 1px solid #e5e7eb;
}

.faq-question:last-child {
  border-bottom: none;
}

.faq-question button {
  width: 100%;
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s;
}

.faq-question button:hover {
  background-color: #f8fafc;
}

.faq-question button span {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a472a;
  flex: 1;
  padding-right: 1rem;
}

.faq-answer {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: #f8fafc;
}

.faq-answer p {
  margin: 0;
  padding: 1.25rem 1.5rem;
  color: #4a5568;
  line-height: 1.6;
  font-size: 1rem;
}

.faq-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.faq-title {
  font-size: 2rem;
  font-weight: 700;
  color: #1a472a;
  margin-bottom: 1rem;
  /* padding-top: 1rem; */
}

.faq-subtitle {
  color: #6b7280;
  font-size: 1.1rem;
}

/* Desktop styles */
@media (min-width: 768px) {
  .faq-container {
    padding: 2rem 0;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .faq-container {
    margin-top: 0;
    /* padding: 1rem 0; */
  }
  
  .faq-card {
    padding: 0 10px;
  }

  .faq-title {
    font-size: 1.75rem;
    padding-top: 0;
  }
} 