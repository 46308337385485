.modal {
  background-color: #e2e8f0;
  padding: 2.5rem;
  border-radius: 12px;
  width: 500px;
  max-width: 90%;
  position: relative;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transition: transform 0.3s ease;
  z-index: 1000;
  border: 1px solid #cbd5e0;
}

.video-modal {
  background-color: #e2e8f0;
  padding: 1.5rem;
  border-radius: 12px;
  width: 70em;
  max-width: 90%;
  position: absolute;
  z-index: 1000;
  border: 1px solid #cbd5e0;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(4px);
}

.closeButton {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  padding: 0.5rem;
  cursor: pointer;
  background: none;
  border: none;
  color: #1a472a;
  font-size: 1.5rem;
  transition: all 0.2s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover {
  color: #ef4444;
  transform: scale(1.1);
  background-color: rgba(239, 68, 68, 0.1);
}

/* Form styles */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

/* Field styles */
.field {
  width: 100%;
  margin: 0.75rem 0;
}

.field input,
.field textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #f8fafc;
  color: #1a202c;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.field input:focus,
.field textarea:focus {
  outline: none;
  border-color: #1a472a;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.field input::placeholder,
.field textarea::placeholder {
  color: #64748b;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  z-index: 10;
  backdrop-filter: blur(4px);
}

/* .spinner {
  transform: translateY(-25%); /* Move the spinner up 
} */

@media only screen and (max-width: 700px) {
  .modal {
    width: 95%;
    max-width: 95%;
    padding: 2rem;
    margin: 1rem;
  }

  .video-modal {
    width: 95%;
    max-width: 95%;
    margin-top: 2rem;
    padding: 1.5rem;
  }
}
