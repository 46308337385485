:root {
  --primary-color: #1a472a;
  --primary-hover: #2c6a40;
  --error-color: #dc3545;
  --text-color: #333;
  --border-color: #e1e1e1;
  --background-color: #ffffff;
  --help-bar-color: #2d2d2d;
  --input-background: #eef1f4;
  --box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f5f5f5;
}

.auth-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  padding-bottom: 80px;
  margin-top: -40px;
}

.auth-form-container {
  background: var(--background-color);
  padding: 2.5rem 3.5rem;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  width: 100%;
  max-width: 50%;
  min-width: 500px;
  position: relative;
  transition: transform 0.3s ease;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .auth-form-container {
    max-width: 90%;
    min-width: unset;
    padding: 2rem;
  }
}

.auth-form-container:hover {
  transform: translateY(-2px);
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tab {
  display: flex;
  margin-bottom: 1.25rem;
  border-bottom: none;
  gap: 0.75rem;
}

.tab-item {
  flex: 1;
  text-align: center;
  padding: 0.875rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-color);
  transition: all 0.3s ease;
  border-radius: 8px;
  background-color: var(--input-background);
}

.tab-item.active {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.form-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-bottom: 0.25rem;
}

.inputStyle {
  width: 100%;
  padding: 0.875rem 1.25rem;
  border: 2px solid var(--input-background);
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: var(--input-background);
  color: var(--text-color);
  font-weight: 500;
  box-sizing: border-box;
  height: 3rem;
}

.inputStyle:focus {
  outline: none;
  border-color: var(--primary-color);
  background-color: var(--input-background);
  box-shadow: 0 0 0 4px rgba(26, 71, 42, 0.1);
}

.inputStyle::placeholder {
  color: #6b7280;
  font-weight: 400;
}

.submitButton {
  width: 100%;
  padding: 0.875rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
  box-sizing: border-box;
  height: 3rem;
}

.submitButton:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
  box-shadow: 0 6px 15px rgba(26, 71, 42, 0.2);
}

.submitButton:active {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.link-button {
  background: none;
  border: none;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  margin-top: 0.25rem;
  transition: color 0.3s ease;
  text-align: center;
  padding: 0.25rem;
}

.link-button:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

.error-message-login {
  color: var(--error-color);
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  z-index: 10;
  backdrop-filter: blur(4px);
}

.help-bar {
  background-color: var(--help-bar-color);
  color: white;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.help-bar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
}

.help-bar-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.help-bar-link:hover {
  opacity: 0.8;
}

.spinner {
  transform: translateY(-25%);
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .auth-form-container {
    width: 40vh !important;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem 1.5rem;
  }

  .auth-form {
    width: 100%;
  }

  .auth-tabs {
    margin: -2rem -1.5rem 1.5rem;
  }

  .auth-input {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }

  .auth-button {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .forgot-password {
    font-size: 0.9rem;
  }
}

