.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

.pacman {
  position: relative;
  width: 60px;
  height: 60px;
}

.pacman > div:first-child,
.pacman > div:nth-child(2) {
  width: 0;
  height: 0;
  border: 30px solid #1a472a;
  border-right-color: transparent;
  border-radius: 30px;
  position: absolute;
  left: 0;
}

.pacman > div:first-child {
  animation: rotate-first 0.5s infinite;
}

.pacman > div:nth-child(2) {
  animation: rotate-second 0.5s infinite;
}

.pacman > div:nth-child(3),
.pacman > div:nth-child(4),
.pacman > div:nth-child(5) {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #1a472a;
  position: absolute;
  top: 25px;
  left: 70px;
  animation: move 0.5s infinite linear;
}

.pacman > div:nth-child(3) {
  animation-delay: 0s;
}

.pacman > div:nth-child(4) {
  animation-delay: -0.15s;
  left: 95px;
}

.pacman > div:nth-child(5) {
  animation-delay: -0.3s;
  left: 120px;
}

@keyframes rotate-first {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-44deg);
  }
}

@keyframes rotate-second {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(44deg);
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
} 