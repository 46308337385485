.pool-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-items: center;
  margin-top: 2rem;
}

.pool-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  /* gap: 1rem; */
}

.pool-form-group {
  width: 100%;
  margin-bottom: 1rem;
}

.form-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a472a;
  text-align: center;
  margin-bottom: 1.5rem;
  letter-spacing: -0.5px;
}

.form-label {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a472a;
  margin-bottom: 0.5rem;
  display: block;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.form-input,
.form-select {
  width: 100%;
  height: 48px;
  padding: 0.75rem 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  color: #2d3748;
  background-color: #ffffff;
  font-weight: 500;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

/* Specific styles for number inputs */
input[type="number"].form-input {
  -moz-appearance: textfield;
  appearance: textfield;
  width: 100%;
  max-width: 500px;
}

/* Remove spinner buttons */
input[type="number"].form-input::-webkit-outer-spin-button,
input[type="number"].form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Ensure select has same width */
.form-select {
  width: 100%;
  max-width: 500px;
  padding-right: 2.5rem;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #1a472a;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.form-input::placeholder,
.form-select::placeholder {
  color: #64748b;
}

.form-input.error,
.form-select.error {
  border-color: #e53e3e;
  background-color: #fff5f5;
}

.pool-form-error-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(229, 62, 62, 0.1);
  border-radius: 6px;
  font-weight: 500;
}

.pool-form-error-icon {
  color: #e53e3e;
  flex-shrink: 0;
}

/* Style select dropdown arrow */
.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.form-checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  width: 100%;
}

.form-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #1a472a;
  border-radius: 4px;
  cursor: pointer;
  accent-color: #1a472a;
}

.form-checkbox-label {
  font-size: 0.95rem;
  color: #1a472a;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  line-height: 1.4;
}

.edit-disabled-message {
  font-size: 0.875rem;
  color: #991b1b;
  border-color: red;
  background-color: #fee2e2;
  /* padding: 0.75rem 1rem; */
  border-radius: 6px;
  margin: 0;
  text-align: center;
  border: 1px solid #fecaca;
  width: 100%;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.form-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.form-button.primary {
  background-color: #1a472a;
  color: white;
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.form-button.primary:hover {
  background-color: #2c6a43;
  transform: translateY(-1px);
  box-shadow: 0 6px 15px rgba(26, 71, 42, 0.2);
}

.form-button.secondary {
  background-color: #e2e8f0;
  color: #4a5568;
}

.form-button.secondary:hover {
  background-color: #cbd5e0;
}

.form-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .form-actions {
    flex-direction: column;
  }

  .form-button {
    width: 100%;
  }
}

.pool-modal-nav-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  /* border-top: 1px solid #e2e8f0; */
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  z-index: 2;
}

/* Confirmation Modal Styles */
.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
}

.confirmation-modal-header {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a472a;
  margin-bottom: 1rem;
}

.confirmation-modal-content {
  color: #4b5563;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.confirmation-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.confirmation-modal-button {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.confirmation-modal-button.confirm {
  background-color: #1a472a;
  color: white;
  border: none;
}

.confirmation-modal-button.confirm:hover {
  background-color: #2c6d42;
}

.confirmation-modal-button.cancel {
  background-color: white;
  color: #4b5563;
  border: 1px solid #d1d5db;
}

.confirmation-modal-button.cancel:hover {
  background-color: #f3f4f6;
  border-color: #9ca3af;
} 