.tournament-stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 1.5rem 0;
}

.stat-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #1a472a;
  box-shadow: 
    0 1px 2px rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.04);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.05),
    0 8px 16px rgba(0, 0, 0, 0.05);
  border-color: #1a472a;
}

.stat-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 1px;
  background: #f1f5f9;
}

.stat-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  width: 100%;
}

.stat-title {
  font-size: 0.75rem;
  color: #1a472a;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.stat-text {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  background-color: rgba(20, 83, 45, 0.1);
  color: #14532d;
  border: 1px solid rgba(20, 83, 45, 0.3);
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  min-width: 32px;
  justify-content: center;
  transition: all 0.2s ease;
}

.stat-text:hover {
  background-color: rgba(20, 83, 45, 0.15);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .tournament-stats {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.875rem;
    margin: 1rem auto;
    max-width: 90%;
    padding: 0;
  }

  .stat-item {
    padding: 0.75rem;
    min-height: 80px;
  }

  .stat-item:not(:last-child)::after {
    display: none;
  }

  .stat-content {
    gap: 0.5rem;
  }

  .stat-title {
    font-size: 0.7rem;
  }

  .stat-text {
    font-size: 0.85rem;
    padding: 3px 8px;
  }
}

/* Small mobile adjustments */
@media (max-width: 480px) {
  .tournament-stats {
    max-width: 95%;
    gap: 0.75rem;
  }

  .stat-item {
    min-height: 70px;
  }
} 