/* AllPools specific table styles */
.golf-pools-table {
    width: 100%;
    border-collapse: collapse;
}

.golf-pools-table th,
.golf-pools-table td {
    padding: 0.75rem 1.25rem;
    text-align: center;
    border-bottom: 1px solid #e5e7eb;
}

.all-pools-pool-number {
    width: 80px;
}

.all-pools-tour {
    width: 120px;
    min-width: 120px;
    text-align: left;
    padding-left: 16px;
}

.all-pools-tournament {
    width: 120px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1.25rem;
}

.all-pools-date {
    width: 90px;
    padding-right: 1.25rem;
}

.all-pools-status {
    width: 140px;
    padding-right: 1.25rem;
}

.golf-pools-badge-action {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
    min-width: 110px;
}

.golf-pools-badge-action.not-started {
    background-color: #f3f4f6;
    color: #374151;
    border: 1px solid #d1d5db;
}

.golf-pools-badge-action.in-progress {
    background-color: #dcfce7;
    color: #166534;
    border: 1px solid #166534;
}

.golf-pools-badge-action.suspended {
    background-color: #fee2e2;
    color: #dc2626;
    border: 1px solid #dc2626;
}

.golf-pools-badge-action.completed,
.golf-pools-badge-action.official {
    background-color: #dbeafe;
    color: #1e40af;
    border: 1px solid #1e40af;
}

.golf-pools-badge-action.cancelled {
    background-color: #fecaca;
    color: #dc2626;
    border: 1px solid #dc2626;
}

/* Mobile styles */
@media (max-width: 768px) {
    .pool-card {
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .pool-card-header {
        margin-bottom: 1rem;
    }

    .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .item-header h3 {
        margin: 0;
        font-size: 1.125rem;
        font-weight: 600;
    }

    .pool-card-detail {
        display: flex;
    
        margin-bottom: 0.5rem;
    }

    .detail-label {
        font-weight: 500;
        color: #6b7280;
    }

    .detail-value {
        color: #111827;
    }
}

.pool-card {
    background: white;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pool-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    gap: 12px;
}

.pool-card-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    min-width: 0;
}

.pool-card .status-badge-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
}

.pool-card .golf-pools-badge {
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
}

.pool-card-detail {
    display: flex;
    margin-bottom: 8px;
}

.pool-card-detail .detail-label {
    width: 100px;
    color: #666;
    font-weight: 500;
}

.pool-card-detail .detail-value {
    flex: 1;
    font-weight: 400;
} 